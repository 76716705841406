import React, { useState, useMemo } from 'react'
import Word from './Word'
interface ILeftStoryProps {
  story?: any
  handleClickWord?: any
  activeIndex?: any
  showPinYin?: boolean
  chineseWordFontSize?: any
}

const chevronUp = <i className='fa fa-chevron-up' />
const chevronDown = <i className='fa fa-chevron-down' />

const LeftStory: React.FC<ILeftStoryProps> = (props: any) => {
  const [showMessage, setShowMessage] = useState(-1)
  const [showChinese, setShowChinese] = useState(-1)
  const [showTranslation, setShowTranslation] = useState(false)

  const { handleClickWord, story, activeIndex, showPinYin, chineseWordFontSize } = props
  const data: any = [[]]
  story.storyContent.forEach((wordObj, index) => {
    const isIncludePTag =
      wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')

    if (isIncludePTag && index) {
      data.push([])
    } else data[data.length - 1].push(wordObj)
  })

  const handleChangeTra = () => {
    setShowTranslation(!showTranslation)
  }
  const iconTra = useMemo(() => {
    return showTranslation ? chevronUp : chevronDown
  }, [showTranslation])

  return (
    <div className='w-col w-col-7 content-left'>
      <div
        style={{ fontSize: chineseWordFontSize?.storyContent, lineHeight: '1.5', letterSpacing: '1.5px' }}
        className='story-content story-event-select'
      >
        <>
          {data?.map((sentance, sIndex) => (
            <div
              key={sIndex}
              className={sIndex === showChinese ? 'sentance-hover p-1' : '  p-1'}
              onMouseEnter={() => {
                setShowMessage(sIndex)
              }}
              onMouseLeave={() => {
                setShowMessage(-1)
              }}
            >
              {sentance?.map((wordObj, index) => {
                const isOldWord = story?.oldWords?.includes(wordObj.word)
                const isNewWord =
                  (!story?.newWord1 ? false : story?.newWord1 === wordObj.word) ||
                  (!story?.newWord2 ? false : story?.newWord2 === wordObj.word)
                const isIncludePTag =
                  wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')
                const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
                return (
                  <React.Fragment key={index}>
                    {isIncludePTag && index ? <br /> : ''}
                    {stripedWord.length ? (
                      <Word
                        key={index}
                        index={`L${sIndex + '-' + index}`}
                        isActive={`L${sIndex + '-' + index}` === activeIndex}
                        text={stripedWord}
                        isNewWord={isNewWord}
                        textObj={wordObj}
                        isOldWord={!isNewWord && isOldWord}
                        onClick={handleClickWord}
                        pinYinText={showPinYin ? wordObj?.pinyin : null}
                        chineseWordFontSize={chineseWordFontSize}
                      />
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          ))}
        </>
      </div>
      <div className='bg-light-gray translation-box'>
        <div
          className='translation-button'
          onClick={() => {
            handleChangeTra()
          }}
        >
          {showTranslation ? `  Hide ` : `  Show `} English Translation
          {iconTra}
        </div>

        {showTranslation && (
          <>
            {/* <div className='pt-10 border-bottom'></div> */}
            <div
              style={{ fontSize: chineseWordFontSize?.storyContent, lineHeight: '1.5', letterSpacing: '1.5px' }}
              className='story-content story-event-select m-b-10'
            >
              {story?.englishContent?.map((sentance, index) => (
                <div
                  className={index === showMessage ? 'sentance-hover translation-text' : 'translation-text'}
                  onMouseEnter={() => {
                    setShowChinese(index)
                  }}
                  onMouseLeave={() => {
                    setShowChinese(-1)
                  }}
                >
                  {sentance}
                </div>
              ))}
              {story?.englishContent?.length > 0 && (
                <div className='hight_light_text_main_div'>
                  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M13.5003 5C13.4344 5.00033 13.3701 4.98057 13.3158 4.94334C13.2615 4.90612 13.2198 4.85321 13.1964 4.79169L12.6777 3.44288C12.6672 3.4157 12.6511 3.39101 12.6305 3.37041C12.6099 3.3498 12.5852 3.33373 12.5581 3.32323L11.2089 2.80414C11.1474 2.78056 11.0945 2.73889 11.0572 2.68464C11.02 2.63038 11 2.5661 11 2.50027C11 2.43444 11.02 2.37016 11.0572 2.31591C11.0945 2.26166 11.1474 2.21999 11.2089 2.1964L12.5577 1.67771C12.5848 1.6672 12.6095 1.65113 12.6301 1.63053C12.6507 1.60992 12.6668 1.58524 12.6773 1.55806L13.1964 0.208858C13.22 0.147399 13.2617 0.0945372 13.3159 0.0572492C13.3702 0.0199612 13.4344 0 13.5003 0C13.5661 0 13.6304 0.0199612 13.6846 0.0572492C13.7389 0.0945372 13.7806 0.147399 13.8041 0.208858L14.3228 1.55767C14.3333 1.58485 14.3494 1.60953 14.37 1.63014C14.3906 1.65074 14.4153 1.66681 14.4425 1.67732L15.7835 2.19327C15.8474 2.21698 15.9025 2.25982 15.9413 2.31597C15.98 2.37211 16.0005 2.43883 16 2.50704C15.999 2.57173 15.9786 2.63462 15.9415 2.68759C15.9043 2.74056 15.8522 2.78116 15.7917 2.80414L14.4429 3.32284C14.4157 3.33334 14.391 3.34941 14.3704 3.37002C14.3498 3.39062 14.3337 3.41531 14.3232 3.44249L13.8041 4.79169C13.7807 4.85321 13.7391 4.90612 13.6848 4.94334C13.6305 4.98057 13.5661 5.00033 13.5003 5Z'
                      fill='#007B59'
                    />
                    <path
                      d='M13.5003 13C13.4344 13.0003 13.3701 12.9806 13.3158 12.9433C13.2615 12.9061 13.2198 12.8532 13.1964 12.7917L12.6777 11.4429C12.6672 11.4157 12.6511 11.391 12.6305 11.3704C12.6099 11.3498 12.5852 11.3337 12.5581 11.3232L11.2089 10.8041C11.1474 10.7806 11.0945 10.7389 11.0572 10.6846C11.02 10.6304 11 10.5661 11 10.5003C11 10.4344 11.02 10.3702 11.0572 10.3159C11.0945 10.2617 11.1474 10.22 11.2089 10.1964L12.5577 9.67771C12.5848 9.6672 12.6095 9.65113 12.6301 9.63053C12.6507 9.60992 12.6668 9.58524 12.6773 9.55806L13.1964 8.20886C13.22 8.1474 13.2617 8.09454 13.3159 8.05725C13.3702 8.01996 13.4344 8 13.5003 8C13.5661 8 13.6304 8.01996 13.6846 8.05725C13.7389 8.09454 13.7806 8.1474 13.8041 8.20886L14.3228 9.55767C14.3333 9.58485 14.3494 9.60953 14.37 9.63014C14.3906 9.65074 14.4153 9.66681 14.4425 9.67732L15.7835 10.1933C15.8474 10.217 15.9025 10.2598 15.9413 10.316C15.98 10.3721 16.0005 10.4388 16 10.507C15.999 10.5717 15.9786 10.6346 15.9415 10.6876C15.9043 10.7406 15.8522 10.7812 15.7917 10.8041L14.4429 11.3228C14.4157 11.3333 14.391 11.3494 14.3704 11.37C14.3498 11.3906 14.3337 11.4153 14.3232 11.4425L13.8041 12.7917C13.7807 12.8532 13.7391 12.9061 13.6848 12.9433C13.6305 12.9806 13.5661 13.0003 13.5003 13Z'
                      fill='#007B59'
                    />
                    <path
                      d='M5.50027 5C5.43444 5.00033 5.37007 4.98057 5.31577 4.94334C5.26147 4.90612 5.21982 4.85321 5.1964 4.79169L4.6777 3.44288C4.6672 3.4157 4.65113 3.39101 4.63052 3.37041C4.60992 3.3498 4.58524 3.33373 4.55806 3.32323L3.20886 2.80414C3.1474 2.78056 3.09454 2.73889 3.05725 2.68464C3.01996 2.63038 3 2.5661 3 2.50027C3 2.43444 3.01996 2.37016 3.05725 2.31591C3.09454 2.26166 3.1474 2.21999 3.20886 2.1964L4.55767 1.67771C4.58485 1.6672 4.60953 1.65113 4.63013 1.63053C4.65074 1.60992 4.66681 1.58524 4.67731 1.55806L5.1964 0.208858C5.21998 0.147399 5.26165 0.0945372 5.31591 0.0572492C5.37016 0.0199612 5.43444 0 5.50027 0C5.5661 0 5.63038 0.0199612 5.68463 0.0572492C5.73888 0.0945372 5.78055 0.147399 5.80414 0.208858L6.32283 1.55767C6.33334 1.58485 6.34941 1.60953 6.37001 1.63014C6.39062 1.65074 6.4153 1.66681 6.44248 1.67732L7.78348 2.19327C7.84744 2.21698 7.90254 2.25982 7.94128 2.31597C7.98002 2.37211 8.00052 2.43883 7.99999 2.50704C7.999 2.57173 7.97861 2.63462 7.94147 2.68759C7.90433 2.74056 7.85215 2.78116 7.79168 2.80414L6.44287 3.32284C6.41569 3.33334 6.39101 3.34941 6.3704 3.37002C6.3498 3.39062 6.33373 3.41531 6.32322 3.44249L5.80414 4.79169C5.78071 4.85321 5.73907 4.90612 5.68477 4.94334C5.63047 4.98057 5.5661 5.00033 5.50027 5Z'
                      fill='#007B59'
                    />
                    <rect
                      x='9.19141'
                      y='4.70711'
                      width='3'
                      height='12'
                      rx='0.5'
                      transform='rotate(45 9.19141 4.70711)'
                      stroke='#007B59'
                    />
                    <path
                      d='M8.4843 4.70711C8.87482 4.31658 9.50799 4.31658 9.89851 4.70711L11.3127 6.12132C11.7033 6.51184 11.7033 7.14501 11.3127 7.53553L9.19141 9.65685L6.36298 6.82843L8.4843 4.70711Z'
                      fill='#007B59'
                    />
                  </svg>

                  <p className='hight_light_text'>
                    This translation has been automatically generated. It may contain errors or inaccuracies.
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export { Word }
export default LeftStory
