import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useSelector } from 'react-redux'
import { characterConst, reviewList } from 'shared/constant'
import Marquee from 'react-fast-marquee'
import { useLocation } from 'react-router-dom'

const SignUp = () => {
  const { pathname } = useLocation()
  const { languageInfo, preferenceType }: any = useSelector<any>(({ onboarding }) => onboarding)

  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    if (pathname.includes('/lead')) {
      const exitsScript = document.getElementById('temp')
      const script = document.createElement('script')
      script.src = 'https://api.memberstack.io/static/memberstack.js?webflow'
      script.setAttribute('data-memberstack-id', '16994eed7bdde3781a57968be24dd38e')
      script.async = true

      // document.body.removeChild(script)
      exitsScript && document.head.removeChild(exitsScript)
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }
  }, [])

  return (
    <div className='trusted_learners_grid_div'>
      <div className='left_sec_div'>
        <div className='logo_div mob_hide_img'>
          <img className='logo_img' src={'/images/maayot-green-logo-small.svg'} alt='Image' />
        </div>
        <div className='choose-lunguage-main-div supercharge_main_div'>
          <div className='common-title-sec'>
            <h2
              className='screen-sub-title mb-3'
              data-aos='fade-down'
              data-aos-duration='1000'
              data-aos-once='true'
              data-aos-delay='100'
            >
              Supercharge your {languageInfo?.language || 'Chinese'} learning
            </h2>
            <p
              className='screen-para-text fw-500'
              data-aos='fade-down'
              data-aos-duration='1000'
              data-aos-delay='400'
              data-aos-once='true'
            >
              Start your learning journey with maayot.
            </p>
          </div>
          <div className='login-field-main-div'>
            <div data-aos='fade-down' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true'>
              <form
                id='email-form'
                name='email-form'
                data-name='Email Form'
                data-ms-form='signup'
                className='form-2 margin-top'
              >
                <div className='form-group' style={{ display: 'none' }}>
                  <input
                    type='text'
                    style={{ visibility: 'hidden' }}
                    value={preferenceType || characterConst.simplified}
                    name='Character'
                    data-name='Character'
                    id='Character'
                    data-ms-member='character'
                  />
                </div>
                <div className='form-group' style={{ display: 'none' }}>
                  <input
                    type='text'
                    style={{ visibility: 'hidden' }}
                    value='Beginner'
                    name='level'
                    data-name='level'
                    id='level-2'
                    data-ms-member='level'
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-input-2 w-input'
                    maxLength={256}
                    name='full-name-2'
                    data-name='Full Name 2'
                    placeholder='First Name'
                    id='full-name-2'
                    data-ms-member='first-name'
                    required
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    className='form-input-2 w-input'
                    maxLength={256}
                    name='email-2'
                    data-name='Email 2'
                    placeholder='Email address'
                    id='email-2'
                    data-ms-member='email'
                    required
                  />
                </div>
                <div className='form-group' style={{ marginBottom: '0px' }}>
                  <input
                    type='password'
                    className='form-input-2 w-input'
                    maxLength={256}
                    name='Password 2'
                    data-name='Password 2'
                    placeholder='Create password'
                    id='password-2'
                    data-ms-member='password'
                    required
                  />
                </div>
                <button
                  type='submit'
                  data-wait='Please wait...'
                  className='common-btn-cls green-btn-class input-btn free-trial-btn'
                  data-ms-membership={'5ef93844eed2c40004936aeb'}
                >
                  {'Start my learning'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='right_sec_div'>
        <div className='w-100'>
          <div className='mob_logo_div'>
            <img className='mob_logo_img' src={'/images/maayot-text-svg.svg'} alt='Image' />
          </div>
          <div className='trusted_sec'>
            <h3 className='section_Heading'> Trusted by learners and schools </h3>

            <div className='reviews_sec'>
              <div className='review_card_box'>
                <img className='playstore_card' src={'/images/playstore_card.png'} alt='Image' />
              </div>
              <div className='review_card_box'>
                <img className='school_card' src={'/images/school_card_1.png'} alt='Image' />
              </div>
              <div className='review_card_box'>
                <img className='appstore_card' src={'/images/appstore_card.png'} alt='Image' />
              </div>
              <div className='review_card_box'>
                <img className='large_school_card' src={'/images/school_card_2.png'} alt='Image' />
              </div>
            </div>

            <div className='available_app_sec'>
              <h6 className='available_text'> Available on </h6>
              <div className='app_icon'>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/app_store.png'} alt='appstore' />
                </div>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/play_store.png'} alt='playstore' />
                </div>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/Opera.png'} alt='opera' />
                </div>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/safari.png'} alt='safari' />
                </div>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/google_chrome.png'} alt='chrome' />
                </div>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/fire_fox.png'} alt='firefox' />
                </div>
                <div className='app_icon_box'>
                  <img className='app_icon_img' src={'/images/microsoft_edge.png'} alt='edge' />
                </div>
              </div>
            </div>

            <div className='testimonial_margin_sec'>
              <Marquee autoFill>
                {reviewList.map((reviews) => (
                  <div>
                    {reviews.map((review) => (
                      <div className={`common_testimonial_card ${review?.className}`} key={review?.username}>
                        <div className='star_div'>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.389 18.7506C15.2574 18.7511 15.1291 18.7101 15.0222 18.6334L9.99836 14.9912L4.97453 18.6334C4.8672 18.7113 4.73789 18.753 4.6053 18.7525C4.47271 18.752 4.34371 18.7094 4.23695 18.6307C4.13019 18.5521 4.0512 18.4416 4.0114 18.3151C3.9716 18.1886 3.97305 18.0528 4.01554 17.9272L5.97492 12.1236L0.896795 8.64122C0.786799 8.56587 0.703781 8.45733 0.65987 8.33144C0.615959 8.20555 0.613456 8.06892 0.652727 7.9415C0.691998 7.81409 0.770985 7.70257 0.878147 7.62325C0.985309 7.54392 1.11503 7.50095 1.24836 7.5006H7.5132L9.40383 1.68224C9.44455 1.55662 9.52402 1.44713 9.63083 1.36948C9.73764 1.29183 9.8663 1.25 9.99836 1.25C10.1304 1.25 10.2591 1.29183 10.3659 1.36948C10.4727 1.44713 10.5522 1.55662 10.5929 1.68224L12.4835 7.50255H18.7484C18.8818 7.50248 19.0119 7.54516 19.1193 7.62434C19.2268 7.70351 19.3061 7.81501 19.3456 7.94252C19.3852 8.07004 19.3828 8.20685 19.339 8.33293C19.2951 8.45901 19.212 8.56773 19.1019 8.64317L14.0218 12.1236L15.98 17.9256C16.0117 18.0195 16.0206 18.1197 16.006 18.2177C15.9914 18.3158 15.9537 18.409 15.8959 18.4896C15.8382 18.5702 15.7621 18.6359 15.674 18.6813C15.5858 18.7267 15.4881 18.7504 15.389 18.7506Z'
                              fill='#49A48E'
                            />
                          </svg>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.389 18.7506C15.2574 18.7511 15.1291 18.7101 15.0222 18.6334L9.99836 14.9912L4.97453 18.6334C4.8672 18.7113 4.73789 18.753 4.6053 18.7525C4.47271 18.752 4.34371 18.7094 4.23695 18.6307C4.13019 18.5521 4.0512 18.4416 4.0114 18.3151C3.9716 18.1886 3.97305 18.0528 4.01554 17.9272L5.97492 12.1236L0.896795 8.64122C0.786799 8.56587 0.703781 8.45733 0.65987 8.33144C0.615959 8.20555 0.613456 8.06892 0.652727 7.9415C0.691998 7.81409 0.770985 7.70257 0.878147 7.62325C0.985309 7.54392 1.11503 7.50095 1.24836 7.5006H7.5132L9.40383 1.68224C9.44455 1.55662 9.52402 1.44713 9.63083 1.36948C9.73764 1.29183 9.8663 1.25 9.99836 1.25C10.1304 1.25 10.2591 1.29183 10.3659 1.36948C10.4727 1.44713 10.5522 1.55662 10.5929 1.68224L12.4835 7.50255H18.7484C18.8818 7.50248 19.0119 7.54516 19.1193 7.62434C19.2268 7.70351 19.3061 7.81501 19.3456 7.94252C19.3852 8.07004 19.3828 8.20685 19.339 8.33293C19.2951 8.45901 19.212 8.56773 19.1019 8.64317L14.0218 12.1236L15.98 17.9256C16.0117 18.0195 16.0206 18.1197 16.006 18.2177C15.9914 18.3158 15.9537 18.409 15.8959 18.4896C15.8382 18.5702 15.7621 18.6359 15.674 18.6813C15.5858 18.7267 15.4881 18.7504 15.389 18.7506Z'
                              fill='#49A48E'
                            />
                          </svg>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.389 18.7506C15.2574 18.7511 15.1291 18.7101 15.0222 18.6334L9.99836 14.9912L4.97453 18.6334C4.8672 18.7113 4.73789 18.753 4.6053 18.7525C4.47271 18.752 4.34371 18.7094 4.23695 18.6307C4.13019 18.5521 4.0512 18.4416 4.0114 18.3151C3.9716 18.1886 3.97305 18.0528 4.01554 17.9272L5.97492 12.1236L0.896795 8.64122C0.786799 8.56587 0.703781 8.45733 0.65987 8.33144C0.615959 8.20555 0.613456 8.06892 0.652727 7.9415C0.691998 7.81409 0.770985 7.70257 0.878147 7.62325C0.985309 7.54392 1.11503 7.50095 1.24836 7.5006H7.5132L9.40383 1.68224C9.44455 1.55662 9.52402 1.44713 9.63083 1.36948C9.73764 1.29183 9.8663 1.25 9.99836 1.25C10.1304 1.25 10.2591 1.29183 10.3659 1.36948C10.4727 1.44713 10.5522 1.55662 10.5929 1.68224L12.4835 7.50255H18.7484C18.8818 7.50248 19.0119 7.54516 19.1193 7.62434C19.2268 7.70351 19.3061 7.81501 19.3456 7.94252C19.3852 8.07004 19.3828 8.20685 19.339 8.33293C19.2951 8.45901 19.212 8.56773 19.1019 8.64317L14.0218 12.1236L15.98 17.9256C16.0117 18.0195 16.0206 18.1197 16.006 18.2177C15.9914 18.3158 15.9537 18.409 15.8959 18.4896C15.8382 18.5702 15.7621 18.6359 15.674 18.6813C15.5858 18.7267 15.4881 18.7504 15.389 18.7506Z'
                              fill='#49A48E'
                            />
                          </svg>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.389 18.7506C15.2574 18.7511 15.1291 18.7101 15.0222 18.6334L9.99836 14.9912L4.97453 18.6334C4.8672 18.7113 4.73789 18.753 4.6053 18.7525C4.47271 18.752 4.34371 18.7094 4.23695 18.6307C4.13019 18.5521 4.0512 18.4416 4.0114 18.3151C3.9716 18.1886 3.97305 18.0528 4.01554 17.9272L5.97492 12.1236L0.896795 8.64122C0.786799 8.56587 0.703781 8.45733 0.65987 8.33144C0.615959 8.20555 0.613456 8.06892 0.652727 7.9415C0.691998 7.81409 0.770985 7.70257 0.878147 7.62325C0.985309 7.54392 1.11503 7.50095 1.24836 7.5006H7.5132L9.40383 1.68224C9.44455 1.55662 9.52402 1.44713 9.63083 1.36948C9.73764 1.29183 9.8663 1.25 9.99836 1.25C10.1304 1.25 10.2591 1.29183 10.3659 1.36948C10.4727 1.44713 10.5522 1.55662 10.5929 1.68224L12.4835 7.50255H18.7484C18.8818 7.50248 19.0119 7.54516 19.1193 7.62434C19.2268 7.70351 19.3061 7.81501 19.3456 7.94252C19.3852 8.07004 19.3828 8.20685 19.339 8.33293C19.2951 8.45901 19.212 8.56773 19.1019 8.64317L14.0218 12.1236L15.98 17.9256C16.0117 18.0195 16.0206 18.1197 16.006 18.2177C15.9914 18.3158 15.9537 18.409 15.8959 18.4896C15.8382 18.5702 15.7621 18.6359 15.674 18.6813C15.5858 18.7267 15.4881 18.7504 15.389 18.7506Z'
                              fill='#49A48E'
                            />
                          </svg>
                          <svg
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.389 18.7506C15.2574 18.7511 15.1291 18.7101 15.0222 18.6334L9.99836 14.9912L4.97453 18.6334C4.8672 18.7113 4.73789 18.753 4.6053 18.7525C4.47271 18.752 4.34371 18.7094 4.23695 18.6307C4.13019 18.5521 4.0512 18.4416 4.0114 18.3151C3.9716 18.1886 3.97305 18.0528 4.01554 17.9272L5.97492 12.1236L0.896795 8.64122C0.786799 8.56587 0.703781 8.45733 0.65987 8.33144C0.615959 8.20555 0.613456 8.06892 0.652727 7.9415C0.691998 7.81409 0.770985 7.70257 0.878147 7.62325C0.985309 7.54392 1.11503 7.50095 1.24836 7.5006H7.5132L9.40383 1.68224C9.44455 1.55662 9.52402 1.44713 9.63083 1.36948C9.73764 1.29183 9.8663 1.25 9.99836 1.25C10.1304 1.25 10.2591 1.29183 10.3659 1.36948C10.4727 1.44713 10.5522 1.55662 10.5929 1.68224L12.4835 7.50255H18.7484C18.8818 7.50248 19.0119 7.54516 19.1193 7.62434C19.2268 7.70351 19.3061 7.81501 19.3456 7.94252C19.3852 8.07004 19.3828 8.20685 19.339 8.33293C19.2951 8.45901 19.212 8.56773 19.1019 8.64317L14.0218 12.1236L15.98 17.9256C16.0117 18.0195 16.0206 18.1197 16.006 18.2177C15.9914 18.3158 15.9537 18.409 15.8959 18.4896C15.8382 18.5702 15.7621 18.6359 15.674 18.6813C15.5858 18.7267 15.4881 18.7504 15.389 18.7506Z'
                              fill='#49A48E'
                            />
                          </svg>
                        </div>
                        <h3 className='testimonial_text'>{review.review}</h3>
                        <h4 className='host_name'>{review.username}</h4>
                      </div>
                    ))}
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
